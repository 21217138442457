var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(1, 1fr)', 'repeat(auto-fit, minmax(400px, 1fr))'],
      "gap": "10px"
    }
  }, _vm._l(_vm.nutritionists, function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "w": "100%",
        "margin-bottom": "0",
        "background-color": "white",
        "border-radius": ['8px', '20px'],
        "border": "1px solid #f2f2f2",
        "box-shadow": "0px 5px 30px 0px #0000000D",
        "d": "flex",
        "flex-direction": "column"
      }
    }, [_c('c-flex', {
      attrs: {
        "w": "100%",
        "px": ['10px', '20px'],
        "pb": ['8px', '12px'],
        "pt": ['12px', '16px'],
        "gap": ['10px', '16px']
      }
    }, [_c('c-box', {
      attrs: {
        "min-w": ['50px', '96px'],
        "w": ['50px', '96px'],
        "h": ['50px', '96px'],
        "overflow": "hidden",
        "border-radius": "100%",
        "background-color": "lightGray.900"
      }
    }, [_c('c-image', {
      attrs: {
        "object-fit": "cover",
        "w": "100%",
        "h": "100%",
        "src": _vm.getPhotoNutritionist(item.photoUrl),
        "alt": item.firstName
      }
    })], 1), _c('c-box', {
      attrs: {
        "w": "100%",
        "d": "flex",
        "align-items": "center",
        "justify-content": "space-between"
      }
    }, [_c('c-heading', {
      attrs: {
        "as": "h3",
        "font-size": ['14px', '18px'],
        "line-height": ['21px', '27px'],
        "font-weight": "700",
        "color": "black.900"
      }
    }, [_vm._v(" " + _vm._s(_vm.getFullnameTitle(item)) + " ")]), _c('LabelQuotaNutritionist', {
      attrs: {
        "remaining-quota": item.remainingQuota
      }
    })], 1)], 1), _c('c-flex', {
      attrs: {
        "flex-grow": "1",
        "w": "100%",
        "flex-direction": _vm.type == 'client' ? 'column' : 'row'
      }
    }, [_c('c-box', {
      attrs: {
        "h": "100%",
        "flex-grow": "1",
        "px": ['16px', '20px'],
        "d": "flex",
        "flex-direction": "column",
        "justify-content": _vm.type == 'client' ? 'flex-start' : 'center',
        "gap": ['5px', '16px'],
        "border-top": "1px solid #F2F2F2",
        "pt": ['8px', '13px'],
        "mb": "auto"
      }
    }, [item.specialization ? _c('c-text', {
      staticClass: "line-clamp-1",
      attrs: {
        "font-family": "Roboto",
        "font-size": ['14px', '16px'],
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s(_vm.joinTextSpecialization(item.specialization)) + " ")]) : _vm._e(), _c('c-box', _vm._l(item.education, function (item_, index_) {
      return _c('c-text', {
        key: 'edu' + index_,
        attrs: {
          "font-family": "Roboto",
          "font-size": ['12px', '14px'],
          "color": "darkGray.900",
          "text-transform": "capitalize"
        }
      }, [_vm._v(" " + _vm._s(item_.level) + " - " + _vm._s(item_.university === "0" ? item_.otherUniversity : _vm.getUniversityName(item_.university)) + " ")]);
    }), 1), _c('Rating', {
      attrs: {
        "rating": item.rating
      }
    })], 1), _c('c-flex', {
      attrs: {
        "d": ['flex', 'flex'],
        "mt": ['8px', '18px'],
        "mb": "13px",
        "px": ['10px', '20px']
      }
    }, [_c('c-box', {
      attrs: {
        "w": "100%",
        "display": "flex",
        "flex-direction": _vm.type == 'client' ? 'row' : 'column',
        "align-items": "center",
        "justify-content": "space-between",
        "gap": "4px"
      }
    }, [_c('c-button', {
      attrs: {
        "rounded": "1000px",
        "variant-color": "primary",
        "variant": "outline",
        "h": ['50px', '60px'],
        "w": "100%",
        "max-width": _vm.type == 'client' ? ['130px', '160px'] : '200px',
        "border-width": ['1px', '2px'],
        "font-size": ['12px', '18px'],
        "font-family": "Roboto",
        "font-weight": ['500', '600']
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onOpenDetailNutritionist(item.id);
        }
      }
    }, [_vm._v(" Lihat Profil ")]), _c('c-button', {
      attrs: {
        "rounded": "1000px",
        "variant-color": "primary",
        "h": ['50px', '60px'],
        "font-size": ['12px', '18px'],
        "font-family": "Roboto",
        "font-weight": ['500', '600'],
        "is-disabled": _vm.isDisabledButtonChoose(item) || _vm.usedNutritionist == item.id,
        "w": "100%",
        "min-width": "200px"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onChooseNutritionist(item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.usedNutritionist == item.id ? 'Dipilih' : ['sm', 'md'].includes(_vm.currentBreakpoint) ? _vm.buttonSubmitText : _vm.buttonSubmitTextMd) + " ")])], 1)], 1)], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }