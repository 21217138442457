var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('c-button', {
    attrs: {
      "variant": ['sm', 'md'].includes(_vm.currentBreakpoint) ? 'ghost' : 'solid',
      "rounded": "20px",
      "variant-color": "gray",
      "color": "#333333",
      "font-size": "16px",
      "font-family": "Roboto",
      "font-weight": "400",
      "h": ['38px', '62px'],
      "d": "flex",
      "align-items": "center",
      "justify-content": "center",
      "px": ['0', '24px']
    },
    on: {
      "click": _vm.open
    }
  }, [_c('c-text', {
    attrs: {
      "d": ['none', 'block']
    }
  }, [_vm._v(" Filter ")]), _c('c-box', {
    attrs: {
      "ml": ['0', '8px'],
      "height": "19px",
      "width": "19px",
      "min-height": "19px",
      "min-width": "19px",
      "d": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-filter.svg'),
      "fill": ['sm', 'md'].includes(_vm.currentBreakpoint) ? '#888888' : '#555555'
    }
  })], 1)], 1), _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "on-close": _vm.close,
      "scroll-behavior": "inside",
      "close-on-overlay-click": true,
      "is-centered": ""
    }
  }, [_c('c-modal-content', {
    staticClass: "modal-filter-nutritionist",
    attrs: {
      "rounded-top": "8px",
      "max-height": "calc(100vh - 7.5rem)"
    }
  }, [_c('c-modal-header', {
    attrs: {
      "font-size": "16px",
      "font-weight": "500",
      "color": ['#008C81', '#888888'],
      "h": ['48px', '62px'],
      "d": "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "px": "20px",
      "py": "0"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('c-text', [_vm._v(" " + _vm._s(['sm', 'md'].includes(_vm.currentBreakpoint) ? "Filter" : "Spesialisasi") + " ")]), _c('c-box', {
    attrs: {
      "height": ['10px', '15px'],
      "width": ['10px', '15px'],
      "min-height": ['10px', '15px'],
      "min-width": ['10px', '15px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-divider', {
    attrs: {
      "border-color": "#C4C4C4",
      "m": "0",
      "p": "0"
    }
  }), _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "h": ['14.58px', '21.86px'],
      "w": ['14.58px', '21.86px'],
      "alt": "icon",
      "pos": "absolute",
      "top": "50%",
      "left": "15px",
      "transform": "translateY(-50%)",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "type": "text",
      "w": "100%",
      "placeholder": "Cari",
      "padding-left": "50px",
      "pos": "relative",
      "z-index": "1",
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": ['400', '500'],
      "h": ['48px', '62px'],
      "rounded": "0"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('c-divider', {
    attrs: {
      "border-color": "#C4C4C4",
      "m": "0",
      "p": "0"
    }
  }), _c('c-modal-body', {
    attrs: {
      "p": "0"
    }
  }, [!_vm.search ? _c('c-box', [_c('c-button', {
    attrs: {
      "rounded": "0",
      "variant": "ghost",
      "d": "flex",
      "w": "full",
      "justify-content": "space-between",
      "font-weight": "500",
      "font-size": ['14px', '18px'],
      "color": "#008C81",
      "h": ['48px', '62px']
    },
    on: {
      "click": function click($event) {
        return _vm.handleChange('ALL');
      }
    }
  }, [_vm._v(" Semua Spesialisasi "), _vm.isAllSelected ? _c('c-box', {
    attrs: {
      "height": ['14.66px', '21.99px'],
      "width": ['14.66px', '21.99px'],
      "min-height": ['14.66px', '21.99px'],
      "min-width": ['14.66px', '21.99px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-check.svg'),
      "fill": "#008C81"
    }
  })], 1) : _vm._e()], 1), _c('c-divider', {
    attrs: {
      "border-color": "#C4C4C4",
      "m": "0",
      "p": "0"
    }
  })], 1) : _vm._e(), _vm._l(_vm.filteredOptions, function (option) {
    return _c('c-box', {
      key: option
    }, [_c('c-button', {
      attrs: {
        "rounded": "0",
        "variant": "ghost",
        "d": "flex",
        "w": "full",
        "justify-content": "space-between",
        "font-weight": "500",
        "font-size": ['14px', '18px'],
        "color": "#008C81",
        "h": ['48px', '62px']
      },
      on: {
        "click": function click($event) {
          return _vm.handleChange(option);
        }
      }
    }, [_vm._v(" " + _vm._s(option) + " "), _vm.isSelected(_vm.data, option) ? _c('c-box', {
      attrs: {
        "height": ['14.66px', '21.99px'],
        "width": ['14.66px', '21.99px'],
        "min-height": ['14.66px', '21.99px'],
        "min-width": ['14.66px', '21.99px']
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-check.svg'),
        "fill": "#008C81"
      }
    })], 1) : _vm._e()], 1), _c('c-divider', {
      attrs: {
        "border-color": "#C4C4C4",
        "m": "0",
        "p": "0"
      }
    })], 1);
  })], 2)], 1), _c('c-modal-overlay')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }