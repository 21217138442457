var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "w": "100%",
      "background-color": "white",
      "border-radius": "12px",
      "border": "1px solid #f2f2f2",
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "pos": "relative",
      "margi-bottom": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-direction": "row",
      "align": "center",
      "p": "1rem"
    }
  }, [_c('c-box', {
    attrs: {
      "d": "block",
      "w": ['50px', '96px'],
      "h": ['50px', '96px'],
      "border-radius": "100%",
      "mr": ['8px', '1rem']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "circle",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "w": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "d": "block",
      "w": ['150px', '200px'],
      "h": ['21px', '24px'],
      "mb": "10px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-box', {
    attrs: {
      "d": "block",
      "w": "100px",
      "h": ['16px', '18px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "d": "block",
      "w": ['80px', '100px'],
      "h": ['32px', '50px'],
      "mb": "0",
      "align-self": "center"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1)], 1)], 1), _c('BaseDivider', {
    attrs: {
      "margin": "0"
    }
  }), _c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-direction": _vm.type == 'client' ? 'column' : 'row'
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-dir": "column",
      "flex-grow": "1",
      "p": "1rem",
      "justify": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "70%",
      "h": ['18px', '23px'],
      "mb": ['8px', '1rem']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": "25%",
      "h": ['14px', '21px'],
      "mb": ['8px', '1rem']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['30px', '50px'],
      "h": ['14px', '21px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "flex-dir": _vm.type == 'client' ? 'row' : 'column',
      "gap": "8px",
      "px": "1rem",
      "pb": "1rem",
      "pt": _vm.type == 'client' ? '0' : '1rem'
    }
  }, [_c('c-box', {
    attrs: {
      "w": "180px",
      "h": ['42px', '60px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "w": "100%",
      "h": ['42px', '60px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }