<template>
  <c-flex
    flex-direction="column"
    w="100%"
    background-color="white"
    border-radius="12px"
    border="1px solid #f2f2f2"
    box-shadow="0px 5px 30px 0px #0000000D"
    pos="relative"
    margi-bottom="16px"
  >
    <c-flex
      w="100%"
      flex-direction="row"
      align="center"
      p="1rem"
    >
      <c-box
        d="block"
        :w="['50px', '96px']"
        :h="['50px', '96px']"
        border-radius="100%"
        :mr="['8px', '1rem']"
      >
        <vue-skeleton-loader
          type="circle"
          width="100%"
          height="100%"
          rounded
          animation="fade"
        />
      </c-box>
      <c-flex flex-grow="1">
        <c-flex
          flex-direction="column"
          w="100%"
        >
          <c-box
            d="block"
            :w="['150px', '200px']"
            :h="['21px', '24px']"
            mb="10px"
          >
            <vue-skeleton-loader
              type="rect"
              width="100%"
              height="100%"
              rounded
              animation="fade"
            />
          </c-box>
          <c-box
            d="block"
            w="100px"
            :h="['16px', '18px']"
          >
            <vue-skeleton-loader
              type="rect"
              width="100%"
              height="100%"
              rounded
              animation="fade"
            />
          </c-box>
        </c-flex>
        <c-box
          d="block"
          :w="['80px', '100px']"
          :h="['32px', '50px']"
          mb="0"
          align-self="center"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            rounded
            animation="fade"
          />
        </c-box>
      </c-flex>
    </c-flex>
    <BaseDivider margin="0" />
    <c-flex
      w="100%"
      :flex-direction="type == 'client' ? 'column' : 'row'"
    >
      <c-flex
        w="100%"
        flex-dir="column"
        flex-grow="1"
        p="1rem"
        justify="center"
      >
        <c-box
          w="70%"
          :h="['18px', '23px']"
          :mb="['8px', '1rem']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            rounded
            animation="fade"
          />
        </c-box>
        <!-- <c-box w="35%" :h="['14px', '21px']" mb="8px">
          <vue-skeleton-loader type="rect" width="100%" height="100%" rounded animation="fade" />
        </c-box> -->
        <c-box
          w="25%"
          :h="['14px', '21px']"
          :mb="['8px', '1rem']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            rounded
            animation="fade"
          />
        </c-box>
        <c-box
          :w="['30px', '50px']"
          :h="['14px', '21px']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            rounded
            animation="fade"
          />
        </c-box>
      </c-flex>
      <c-flex
        :flex-dir="type == 'client' ? 'row' : 'column'"
        gap="8px"
        px="1rem"
        pb="1rem"
        :pt="type == 'client' ? '0' : '1rem'"
      >
        <c-box
          w="180px"
          :h="['42px', '60px']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            rounded
            animation="fade"
          />
        </c-box>
        <c-flex
          flex-grow="1"
          w="100%"
          :h="['42px', '60px']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            rounded
            animation="fade"
          />
        </c-flex>
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'
import BaseDivider from '@/components/elements/base-divider.vue'

export default {
  name: 'SkeletonCardNutritionist',
  components: {
    VueSkeletonLoader,
    BaseDivider,
  },
  props: {
    type: {
      type: String,
      default: 'client',
    }, 
  },
}
</script>
