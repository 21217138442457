import { render, staticRenderFns } from "./modal-filter-nutritionist.vue?vue&type=template&id=c0d1775e&scoped=true&"
import script from "./modal-filter-nutritionist.vue?vue&type=script&lang=js&"
export * from "./modal-filter-nutritionist.vue?vue&type=script&lang=js&"
import style0 from "./modal-filter-nutritionist.vue?vue&type=style&index=0&id=c0d1775e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0d1775e",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CModalHeader: require('@chakra-ui/vue').CModalHeader, CDivider: require('@chakra-ui/vue').CDivider, CImage: require('@chakra-ui/vue').CImage, CInput: require('@chakra-ui/vue').CInput, CModalBody: require('@chakra-ui/vue').CModalBody, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
