<template>
  <c-grid
    w="100%"
    :template-columns="['repeat(1, 1fr)', 'repeat(auto-fit, minmax(400px, 1fr))']"
    gap="10px"
  >
    <c-box
      v-for="(item, index) in nutritionists"
      :key="index"
      w="100%"
      margin-bottom="0"
      background-color="white" 
      :border-radius="['8px', '20px']"
      border="1px solid #f2f2f2"
      box-shadow="0px 5px 30px 0px #0000000D"
      d="flex"
      flex-direction="column"
    >
      <c-flex
        w="100%"
        :px="['10px', '20px']"
        :pb="['8px', '12px']"
        :pt="['12px', '16px']"
        :gap="['10px', '16px']"
      >
        <c-box
          :min-w="['50px', '96px']"
          :w="['50px', '96px']"
          :h="['50px', '96px']"
          overflow="hidden"
          border-radius="100%"
          background-color="lightGray.900"
        >
          <c-image
            object-fit="cover"
            w="100%"
            h="100%"
            :src="getPhotoNutritionist(item.photoUrl)"
            :alt="item.firstName"
          />
        </c-box>
        <c-box
          w="100%"
          d="flex"
          align-items="center"
          justify-content="space-between"
        >
          <c-heading
            as="h3"
            :font-size="['14px', '18px']"
            :line-height="['21px', '27px']"
            font-weight="700"
            color="black.900"
          >
            {{ getFullnameTitle(item) }}
          </c-heading>
          <LabelQuotaNutritionist :remaining-quota="item.remainingQuota" />
        </c-box>
      </c-flex>
      <c-flex 
        flex-grow="1"
        w="100%"
        :flex-direction="type == 'client' ? 'column' : 'row'"
      >
        <c-box
          h="100%"
          flex-grow="1"
          :px="['16px', '20px']"
          d="flex"
          flex-direction="column"
          :justify-content="type == 'client' ? 'flex-start' : 'center'"
          :gap="['5px', '16px']"
          border-top="1px solid #F2F2F2"
          :pt="['8px', '13px']"
          mb="auto"
        >
          <c-text
            v-if="item.specialization"
            font-family="Roboto"
            :font-size="['14px', '16px']"
            color="primary.400"
            class="line-clamp-1"
          >
            {{ joinTextSpecialization(item.specialization) }}
          </c-text>
          <c-box>
            <c-text
              v-for="(item_, index_) in item.education"
              :key="'edu' + index_"
              font-family="Roboto"
              :font-size="['12px', '14px']"
              color="darkGray.900"
              text-transform="capitalize"
            >
              {{ item_.level }} -
              {{
                item_.university === "0"
                  ? item_.otherUniversity
                  : getUniversityName(item_.university)
              }}
            </c-text>
          </c-box>
          <Rating :rating="item.rating" />
        </c-box>
        <c-flex
          :d="['flex', 'flex']"
          :mt="['8px','18px']"
          mb="13px"
          :px="['10px', '20px']"
        >
          <c-box
            w="100%"
            display="flex"
            :flex-direction="type == 'client' ? 'row' : 'column'"
            align-items="center"
            justify-content="space-between"
            gap="4px"
          >
            <c-button
              rounded="1000px"
              variant-color="primary"
              variant="outline"
              :h="['50px','60px']"
              w="100%"
              :max-width="type == 'client' ? ['130px','160px'] : '200px'"
              :border-width="['1px','2px']"
              :font-size="['12px','18px']"
              font-family="Roboto"
              :font-weight="['500','600']"
              @click.prevent="onOpenDetailNutritionist(item.id)"
            >
              Lihat Profil
            </c-button>
            <!--            <c-button-->
            <!--                    v-if="!item.whoHandle"-->
            <c-button
              rounded="1000px"
              variant-color="primary"
              :h="['50px','60px']"
              :font-size="['12px','18px']"
              font-family="Roboto"
              :font-weight="['500','600']"
              :is-disabled="isDisabledButtonChoose(item) || (usedNutritionist == item.id)"
              w="100%"
              min-width="200px"
              @click.prevent="onChooseNutritionist(item.id)"
            >
              {{ usedNutritionist == item.id ? 'Dipilih' : (['sm', 'md'].includes(currentBreakpoint) ? buttonSubmitText : buttonSubmitTextMd) }}
            </c-button>
            <!--          <c-button-->
            <!--            v-if="item.whoHandle"-->
            <!--            right-icon="check"-->
            <!--            backgroundColor="#F2F2F2"-->
            <!--            color="primary.400"-->
            <!--            borderRadius="1000px"-->
            <!--            fontFamily="Roboto"-->
            <!--            :fontSize="['12px','18px']"-->
            <!--            :h="['50px','60px']"-->
            <!--            w="100%"-->
            <!--          >-->
            <!--            Dipilih-->
            <!--          </c-button>-->
          </c-box>
        </c-flex>
      </c-flex>
    </c-box>
  </c-grid>
</template>

<script>
import LabelQuotaNutritionist from '@/components/elements/label-quota-nutritionist.vue'
import Rating from '@/views/client/nutritionists/_widgets/rating.vue'
import generalMixin from '@/utils/general-mixins'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'

export default {
  name: 'CardNutritionist',
  components: { Rating, LabelQuotaNutritionist },
  mixins: [generalMixin, mixinsCheckBreakpoints()],
  props: {
    universities: {
      type: Array,
      default: () => [],
    },
    nutritionists: {
      type: Array,
      default: () => [],
    },
    isDisabledChooseNutritionist: {
      type: Boolean,
    },
    type: {
      type: String,
      default: 'client',
    },
    buttonSubmitText: {
      type: String,
      default: 'Pilih',
    },
    buttonSubmitTextMd: {
      type: String,
      default: 'Pilih Ahli Gizi',  
    },
    usedNutritionist: {
      type: String,
      default: 'not-chosen-yet',
    },
  },
  methods: {
    joinTextSpecialization(arraySpecialization) {
      if (typeof arraySpecialization === 'string') {
        return arraySpecialization
      }
      if (arraySpecialization?.length > 0) {
        return arraySpecialization.join(', ')
      }
      return ''
    },
    getFullnameTitle(item) {
      let title = []
      if (item.education && item.education.length > 0) {
        item.education.map((dt) => title.push(dt.title))
      }
      return (
        item?.firstName + ' '+item?.lastName + ` ${title ? title.join(', ') : ''}`
      )
    },
    getUniversityName(id) {
      if (!id) return ''
      if (this.universities.length === 0) return ''
      let a = this.universities.find((dt) => dt.id.toString() === id)
      return a.name
    },
    onChooseNutritionist(id) {
      this.$emit('on-choose-nutritionist', id)
    },
    onOpenDetailNutritionist(id) {
      this.$emit('on-profile-nutritionist', id)
    },
    isDisabledButtonChoose({ isAvailable, remainingQuota }) {
      return !isAvailable || remainingQuota <= 0 || this.isDisabledChooseNutritionist
    },
  },
}
</script>
