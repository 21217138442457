<template>
  <div>
    <c-button
      :variant="['sm', 'md'].includes(currentBreakpoint) ? 'ghost' : 'solid' "
      rounded="20px"
      variant-color="gray"
      color="#333333"
      font-size="16px"
      font-family="Roboto"
      font-weight="400"
      :h="['38px','62px']"
      d="flex"
      align-items="center"
      justify-content="center"
      :px="['0','24px']"
      @click="open"
    >
      <c-text :d="['none','block']">
        Filter
      </c-text>
      <c-box
        :ml="['0','8px']"
        height="19px"
        width="19px"
        min-height="19px"
        min-width="19px"
        d="flex"
        justify-content="center"
        align-items="center"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-filter.svg')"
          :fill="['sm', 'md'].includes(currentBreakpoint) ? '#888888' :'#555555'"
        />
      </c-box>
    </c-button>
    <c-modal
      :is-open="isOpen"
      :on-close="close"
      scroll-behavior="inside"
      :close-on-overlay-click="true"
      is-centered
    >
      <c-modal-content
        rounded-top="8px"
        max-height="calc(100vh - 7.5rem)"
        class="modal-filter-nutritionist"
      >
        <c-modal-header
          font-size="16px"
          font-weight="500"
          :color="['#008C81','#888888']"
          :h="['48px', '62px']"
          d="flex"
          justify-content="space-between"
          align-items="center"
          px="20px"
          py="0"
          @click="close"
        >
          <c-text>
            {{ ['sm', 'md'].includes(currentBreakpoint) ? "Filter" : "Spesialisasi" }}
          </c-text>
          <c-box
            :height="['10px', '15px']"
            :width="['10px', '15px']"
            :min-height="['10px', '15px']"
            :min-width="['10px', '15px']"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-arrow-down.svg')"
              fill="#008C81"
            />
          </c-box>
        </c-modal-header>
        <c-divider
          border-color="#C4C4C4"
          m="0"
          p="0"
        />

        <c-box
          pos="relative"
          w="100%"
        >
          <c-image
            :src="require('@/assets/ic-search.svg')"
            :h="['14.58px','21.86px']"
            :w="['14.58px','21.86px']"
            alt="icon"
            pos="absolute"
            top="50%"
            left="15px"
            transform="translateY(-50%)"
            z-index="2"
          />
          <c-input
            v-model="search"
            type="text"
            w="100%"
            placeholder="Cari"
            padding-left="50px"
            pos="relative"
            z-index="1"
            font-family="Roboto"
            :font-size="['14px', '18px']"
            :font-weight="['400', '500']"
            :h="['48px', '62px']"
            rounded="0"
          />
        </c-box>

        <c-divider
          border-color="#C4C4C4"
          m="0"
          p="0"
        />
        <c-modal-body p="0">
          <c-box v-if="!search">
            <c-button
              rounded="0"
              variant="ghost"
              d="flex"
              w="full"
              justify-content="space-between"
              font-weight="500"
              :font-size="['14px','18px']"
              color="#008C81"
              :h="['48px', '62px']"
              @click="handleChange('ALL')"
            >
              Semua Spesialisasi
              <c-box
                v-if="isAllSelected"
                :height="['14.66px', '21.99px']"
                :width="['14.66px', '21.99px']"
                :min-height="['14.66px', '21.99px']"
                :min-width="['14.66px', '21.99px']"
              >
                <inline-svg
                  :src="require('@/assets/icons/icon-check.svg')"
                  fill="#008C81"
                />
              </c-box>
            </c-button>
            <c-divider
              border-color="#C4C4C4"
              m="0"
              p="0"
            />
          </c-box>

          <c-box
            v-for="(option) in filteredOptions"
            :key="option"
          >
            <c-button
              rounded="0"
              variant="ghost"
              d="flex"
              w="full"
              justify-content="space-between"
              font-weight="500"
              :font-size="['14px','18px']"
              color="#008C81"
              :h="['48px', '62px']"
              @click="handleChange(option)"
            >
              {{ option }}
              <c-box
                v-if="isSelected(data, option)"
                :height="['14.66px', '21.99px']"
                :width="['14.66px', '21.99px']"
                :min-height="['14.66px', '21.99px']"
                :min-width="['14.66px', '21.99px']"
              >
                <inline-svg
                  :src="require('@/assets/icons/icon-check.svg')"
                  fill="#008C81"
                />
              </c-box>
            </c-button>
            <c-divider
              border-color="#C4C4C4"
              m="0"
              p="0"
            />
          </c-box>
        </c-modal-body>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'

export default {
  name: 'ModalFilterNutritionist',
  mixins: [mixinsCheckBreakpoints()],
  props: {
    options: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      search: '',
    }
  },
  computed: {
    isAllSelected() {
      return this.options.length === this.data.length
    },
    filteredOptions() {
      return this.options.filter((option) => {
        return option.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  methods: {
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    },
    isSelected(data, option) {
      return data.includes(option)
    },
    handleChange(option) {
      if (option === 'ALL' && this.isAllSelected) {
        this.$emit('change', [])
        return
      } 
      if (option === 'ALL') {
        this.$emit('change', [...this.options])
        return
      }
      this.$emit('change', option)
    },
  },

}
</script>

<style scoped>
.modal-filter-nutritionist::v-deep {
    max-height: 100vh;
}
</style>